import React, { useEffect, useState } from "react"
import "../assets/styles/page/contact.scss"
// import Header from "../components/header"
import axios from "axios"
import Confetti from "../components/confetti-wrapper/confetti"
import { ToastContainer, toast, TypeOptions } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Contact = () => {
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [cname, setCname] = useState("")
  const [desc, setDesc] = useState("")
  const [showConfetti, setShowConfetti] = useState(false)

  const notify = function (message = "There was an error in handling your request!", status: TypeOptions = "error") {
    toast(message, {
      type: status,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark"
    })
  }

  const submit_hubspot_form = async (
    fname: string,
    lname: string,
    email: string,
    number: string,
    cname: string,
    desc: string
  ) => {
    const portalId = "23104971" // example portal ID (not real)
    const formGuid = "6b779447-f4b6-427b-b980-7380aafa79ac" // example form GUID (not real)
    const config = {
      // important!
      headers: {
        "Content-Type": "application/json"
      }
    }

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: "firstname",
            value: fname
          },
          {
            name: "lastname",
            value: lname
          },
          {
            name: "Email",
            value: email
          },
          {
            name: "phone",
            value: number
          },
          {
            name: "company",
            value: cname
          },
          {
            name: "anything_else",
            value: desc
          }
        ]
      },
      config
    )
    return response
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault() // prevent form submit default behavior
    if (!fname || !lname || !email || !number || !cname || !desc) {
      return notify("Please fill all fields")
    } // if an input field is empty, don't submit the form
    const hubspot_response = await submit_hubspot_form(fname, lname, email, number, cname, desc)
    if (hubspot_response.status === 200) {
      handleCofetti()
    } else {
      notify
    }
  }

  const handleCofetti = () => {
    setShowConfetti(true)
    notify("Thanks you for reaching out, we'd contact you as soon as possible", "success")
    setFname("")
    setLname("")
    setEmail("")
    setNumber("")
    setCname("")
    setDesc("")
    setTimeout(() => {
      setShowConfetti(false)

      // clear the remaining input
    }, 5000)
  }

  return (
    <div className="contact--page">
      <div className="main--container">
        <div className="main--flex--container">
          <div className="left--hand--side">
            <h2>Contact our sales team</h2>
            <p className="explore--about--us">Let’s explore what brooi is all about for you.</p>
            <div className="about--us">
              <div className="about--us--container">
                <ul className="list">
                  <li className="list--item">
                    <div className="svg--wrapper">
                      <svg
                        viewBox="0 0 16 16"
                        style={{
                          height: 16,
                          width: 16,
                          display: "flex",
                          flexShrink: 0,
                          fill: "rgba(0,0,0,0.6)"
                        }}
                        className="thinCheck"
                      >
                        <path
                          fill="currentColor"
                          d="M6.385 14.162c.362 0 .642-.15.84-.444L13.652 3.71c.144-.226.205-.417.205-.602 0-.485-.341-.82-.833-.82-.335 0-.54.123-.746.444l-5.926 9.4-3.042-3.903c-.205-.267-.417-.376-.718-.376-.492 0-.848.348-.848.827 0 .212.075.417.253.629l3.541 4.416c.24.3.492.437.848.437z"
                        ></path>
                      </svg>
                    </div>
                    <div className="text">
                      <p>
                        Brooi is a company that runs an online marketplace with listings for lodging, primarily homestay
                        rentals for vacations, and party activities.
                      </p>
                    </div>
                  </li>
                  <li className="list--item">
                    <div className="svg--wrapper">
                      <svg
                        viewBox="0 0 16 16"
                        style={{
                          height: 16,
                          width: 16,
                          display: "flex",
                          flexShrink: 0,
                          fill: "rgba(0,0,0,0.6)"
                        }}
                        className="thinCheck"
                      >
                        <path
                          fill="currentColor"
                          d="M6.385 14.162c.362 0 .642-.15.84-.444L13.652 3.71c.144-.226.205-.417.205-.602 0-.485-.341-.82-.833-.82-.335 0-.54.123-.746.444l-5.926 9.4-3.042-3.903c-.205-.267-.417-.376-.718-.376-.492 0-.848.348-.848.827 0 .212.075.417.253.629l3.541 4.416c.24.3.492.437.848.437z"
                        ></path>
                      </svg>
                    </div>
                    <div className="text">
                      <p>
                        We connect homeowners or real estate agents with individuals, families, and travelers looking
                        for accommodations that are more like homes than hotels, and apartments for their parties.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {showConfetti && <Confetti />}
          <div className="right--hand--side">
            <div className="input--wrapper">
              <label htmlFor="" className="label">
                First Name
              </label>
              <input
                name="Fname"
                type="text"
                required
                className="input"
                placeholder="Jane"
                value={fname}
                onChange={e => setFname(e.target.value)}
              />
            </div>
            <div className="input--wrapper">
              <label htmlFor="" className="label">
                Last Name
              </label>
              <input
                type="text"
                className="input"
                placeholder="Doe"
                required
                name="Lname"
                value={lname}
                onChange={e => setLname(e.target.value)}
              />
            </div>
            <div className="input--wrapper">
              <label htmlFor="" className="label">
                Phone Number
              </label>
              <input
                name="number"
                type="number"
                className="input"
                placeholder="+23423****344"
                value={number}
                onChange={e => setNumber(e.target.value)}
              />
            </div>
            <div className="input--wrapper">
              <label htmlFor="" className="label">
                Work mail
              </label>
              <input
                name="mail"
                type="email"
                required
                className="input"
                placeholder="Janedoe@gmail.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="input--wrapper">
              <label htmlFor="" className="label">
                Company Name
              </label>
              <input
                name="companyName"
                type="text"
                className="input"
                placeholder="Jane's plc..."
                value={cname}
                onChange={e => setCname(e.target.value)}
              />
            </div>
            <div className="input--wrapper">
              <label htmlFor="" className="label">
                Anything else?
              </label>
              <textarea
                placeholder="Short description of why you're contacting us."
                value={desc}
                onChange={e => setDesc(e.target.value)}
                id="w3review"
                className="input"
                name="w3review"
                rows={4}
                cols={50}
              />
            </div>
            <div className="bottom--text--wrapper">
              <p>By submitting this form, I acknowledge receipt of the Notion Privacy Policy.</p>
              <div className="spacer"></div>
              <p> Fields marked with an asterisk (*) are required.</p>
            </div>
            <div className="submit--btn--wrapper" onClick={handleSubmit}>
              <button className="btn">Submit</button>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
