import React from "react"
import Confettic from "react-confetti"
import useWindowDimensions from "../useWindow"

const Confetti = () => {
  const { height = window.innerHeight, width = window.innerWidth } = useWindowDimensions()
  return (
    <div>
      <Confettic width={Number(width)} height={Number(height)} />
    </div>
  )
}

export default Confetti
